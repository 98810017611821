.DragNDrop {
    text-align: center;
    padding: 20px;
    border: 1px #3D7F39 dashed;
    border-radius: 8px;
    width: 100%;
    margin: auto;
    background-color: #E4F1E3;
    cursor: pointer;
}

.DropBtn {
    border: none;
    text-align: center;
    height: 50px;
    border-radius: 12px;
    font-weight: bold;
    transition-duration: 0.6s;
    cursor: pointer;
}