:root{
  --bs-accordion-active-color: #2E5F2B;
  --bs-accordion-active-bg: #e7f1ff;
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  overflow-x: hidden;
}

*,
::after,
::before {
  box-sizing: border-box;
}

/* img,
svg {
  vertical-align: baseline !important;
} */

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

a {
  text-decoration: none !important;
  color: #333333 !important;
}

button {
  cursor: pointer;
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: .25;
}

.app {
  margin: 0px;
  padding: 0px;
}

.container {
  margin: auto;
  position: relative;
  width: 1240px;
  left: 0px;
  right: 0px;
}

h1 {
  font-size: 30px;
  color: #333;
  margin: 0px;
  padding: 0px;
}

h4 {
  margin: 0px;
  margin-bottom: 6px;
  font-size: 20px;
}

.highlight {
  color: #2E5F2B;
}

.secondary {
  color: #001D55;
}

.alert {
  color: red;
}

button.btn {
  border: 0px;
  outline: 0px;
  margin-right: 15px;
  border-radius: 8px;
  font-size: 15px;
  border: 1px solid #112B5F;
  padding: 12px 18px;
  color: #fff;
}

.btn-primary {
  background-color: #112B5F !important;
  color: #fff;
}

.btn.btn-outline {
  background-color: #fff;
  color: #112B5F;
}

/************** Sign in ****************/

.signin {
  position: fixed;
  /* width: 100%; */
  height: 100%;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 10;
  background-color: #fff;
  display: grid;
  grid-template-columns: 0fr 1fr;
}

.Col {
  padding: 30px 60px;
}

.back img {
  border: 1px solid #6C6C6C;
  background-color: #fff;
  padding: 10px 14px;
  border-radius: 100px;
}

.Col .title {
  font-size: 52px;
  color: #001D55;
  font-weight: 600;
  margin-top: 31px;
}

.bg-img {
  background-color: #EAFFE6;
  max-width: 648px;
}

.bg-img .banner {
  margin-top: 50px;
  text-align: center;
}

.banner>img {
  width: 100%;
}

.form {
  text-align: center;
  align-items: center;
  width: 470px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.form h1 {
  color: #001D55;
  margin-top: 28px;
}

.mobile {
  width: 470px;
  height: 40px;
  padding-left: 5px;
  border: 1px solid #ACACAC;
  border-radius: 8px;
}

.label {
  margin-top: 20px;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: left;
  text-transform: capitalize;
}

button {
  margin-top: 40px;
  width: 480px;
  font-size: 18px;
  color: #fff;
  outline: 0px;
  border: 0px;
  background-color: #2E5F2B;
  border-radius: 8px;
  padding: 10px 0px;
}

.signin button:hover {
  background-color: #2E5F2B;
}

.signin .bold {
  margin-top: 50px;
  text-align: center;
}

.step1 {
  display: none
}

.step2 .otp {
  width: 38px;
  height: 38px;
  border-radius: 8px;
  outline: 0px;
  border: 1px solid #ACACAC;
  margin: 3px;
  font-size: 30px;
  padding: 10px;
}

/* //// */
input[aria-details="groupped-left"] {
  border-right: none;
}

input[aria-details="groupped-left"].is-invalid {
  border-right-width: 1px;
  border-right-style: solid;
}

span[aria-details="groupped-right"] {
  background-color: transparent;
}

/* FORM CONTROL */
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

label {
  display: inline-block;
}

.form-label {
  margin-bottom: 0.5rem;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}

.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0;
}

select {
  word-wrap: normal;
}

button,
select {
  text-transform: none;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  appearance: none;
}

/* Utilities */
.d-flex {
  display: flex !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.w-75 {
  width: 75% !important;
}

/* Padding */
.p-2 {
  padding: 0.5rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

/* Margin */
.m-auto {
  margin: auto !important;
}

.m-0 {
  margin: 0 !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

/* Alignment */
.text-start {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-end {
  text-align: right !important;
}

/* Backgrounds */
.bg-green {
  background-color: #2E5F2B !important;
}

.bg-white {
  background-color: #ffffff;
}

/* Fonts */
.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + .9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + .3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}

/* Font-Weight */
.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

/* Colors */
.color-text {
  color: #6C6C6C
}

.color-dark-text {
  color: #333333;
}

.color-white {
  color: #ffffff !important;
}

/* Button */
[type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance: button;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.error {
  color: red;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
  background-color: #E4F1E3;
  color: #2E5F2B;
}