.base {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 125px);
    width: 100vw;
    margin: -8px;
    background: #e4f1e3;
}

.error {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.neon {
    font-family: 'Anton', sans-serif;
    position: relative;
    display: inline-block;
    color: #e4f1e3;
    text-shadow:
        7px 7px 20px rgb(0 0 0 / 20%),
        -7px -7px 20px rgb(228 241 227),
        -4px -4px 5px rgb(228 241 227),
        0 0 20px rgb(228 241 227),
        0 0 30px rgb(228 241 227),
        4px 4px 5px rgb(0 0 0 / 30%),
        0 0 10px rgb(0 0 0 / 30%);
    font-size: 200px;
}

.message-error {
    font-family: 'Anton', sans-serif;
    position: relative;
    display: inline-block;
    color: #e4f1e3;
    font-size: 32px;
    text-shadow: 1px 2px 4px rgb(0 0 0 / 30%), -7px -7px 20px rgb(228 241 227), -4px -4px 5px rgb(228 241 227), 0 0 20px rgb(228 241 227), 0 0 30px rgb(228 241 227), 4px 4px 5px rgb(0 0 0 / 20%), 0 0 10px rgb(0 0 0 / 30%);
}

// footer {
//     margin-top: 0 !important;
// }