.search-result .header{
    border-bottom:1px solid #e8e8e8;
    padding-bottom:16px;
}

 .search-result .header .title{
    font-size:20px;
    font-weight:600;
}

.search-result{
    padding: 30px 50px;
}

.search-result .header .sort{
    float:right;
}

.search-result .title .right{
    float:right;
    font-size:14px;
    font-weight:500;
}

.search-result .sort select{
    border:0px;
    outline:0px;
    background-color: #fafafa;
    color:#112B5F;
    font-size:14px;
    margin-left:20px;
}

.search-result .farmer-list .search-card .cards_container{
    background-color: #E8E8E8;
    border-radius:12px;
    padding:20px;
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap:20px;
    overflow: hidden;
}

.cards_container .card{
    align-items: center;
    font-family: 'SF Pro Display',sans-serif;
}

.cards_container .card_container{
    background-color: #fff;
    padding:10px;
    border-radius: 12px;
    box-shadow: 0px 3px 6px #00000029;
}

/***************Search Results ****************/

.search-page{
    margin-top:88px;
    display:grid;
    grid-template-columns: 260px auto;
}

.search-page .filters{
    border-right:1px solid #E8E8E8;
    padding-right:30px;
    padding-top:32px;
}

.search-page .filters .head-title{
    font-size:20px;
    color:#333333;
}

.search-page .filters .head .right{
    float:right;
    color:#3D7F39;
    font-size:14px;
    padding-top: 5px;
    cursor: pointer;
    float:right;
}

.search-page .filters .dropdown{
    margin-top:28px;
    border-bottom:1px solid #E8E8E8;
    padding-bottom:8px;
    cursor: pointer;
}

.search-page .filters .dropdown .title{
    font-size:16px;
    color:#333;
}

.search-page .filters .dropdown img{
    float:right;
    width:14px;
    margin-top:4px;
}

.search-page .filters .cat-list{
    display:block;
}

.search-page .filters .cat-list ul{
    margin:0px;
    padding:0px;
    list-style:none;
    margin-top:12px;
    font-size:14px;
}

.search-page .filters .cat-list ul li{
    margin-bottom:14px;
}

.search-page .filters .cat-list .price-list{
    width:100%;
    border:3px solid #112B5F;
}

.search-page .filters .budget .price-slider{
    width:100%;
    background-color: #3D7F39;
    height:4px;
    margin-top:6px;
}

.search-page .filters .budget .min{
    border:1px solid #707070;
    border-radius:100px;
    width:15px;
    height:15px;
    margin-top: -10px;
    float: left;
    background-color: #fff;
}

.search-page .filters .budget .max{
    border:1px solid #707070;
    border-radius:100px;
    width:15px;
    height:15px;
    float:right;
    margin-top:-10px;
    background-color: #fff;
}

.search-page .filters .budget .txt{
    font-size:12px;
    margin-top:18px;
    margin-bottom: 10px;
}

.search-page .search-result .result{
    margin-top:30px;
}

.search-page .filters .budget .right{
    float:right;
}

.search-page .filters .budget .price{
    margin-top:10px;
    font-size:12px;
    color:#333333;
    font-weight: 600;
}

.search_cards{
    border-radius:12px;
    padding:20px;
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:20px;
    overflow: hidden;
}

.search_cards .card{
    align-items: center;
    font-family: 'SF Pro Display',sans-serif;
    cursor:pointer;
    background-color: #fff;
    padding:10px;
    border-radius: 12px;
    box-shadow: 0px 3px 6px #00000029;
    border:1px solid #fcfcfc;
}

