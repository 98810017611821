/**********************          Navbar           ***************************/
.topbar-icon-nav {
    border: 1px solid rgba(51, 51, 51, 0.91);
    border-radius: 50%;
    margin-right: 5px;
    padding: 5px;
}

header {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 88px;
    background: #E4F1E3 0% 0% no-repeat padding-box;
    z-index: 5;
}

nav {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    gap: 10px;
    vertical-align: middle;
}

nav>div {
    padding: 6px 0px;
    text-align: center;
    margin: auto;
}

nav .logo img {
    width: 100px;
}

nav .links a {
    font-size: 14px;
    color: #2E5F2B;
    text-decoration: none;
}


nav .logged .icon {
    margin-right: 12px;
}

nav .logged .profile .arrow_down {
    margin-top: 10px;
    margin-left: 6px;
    vertical-align: top;
    cursor: pointer;
}

/* 
nav .dropdown {
    position: absolute;
    min-width: 180px;
    background-color: #fff;
    height: auto;
    top: 66px;
    box-shadow: 0px 6px 10px #00000052;
    border-radius: 0px 0px 12px 12px;
    padding: 18px 5px 0px 20px;
    text-align: left;
    font-size: 14px;
    display: none;
} */

nav .dropdown li {
    padding-bottom: 20px;
    cursor: pointer;
}

nav .logged .profile .profile_icon {
    width: 28px;
}

nav .logged .profile .profile_icon {
    width: 28px;
}

nav .logged .icon .num {
    background-color: #EB4134;
    padding: 2px 6px;
    color: #fff;
    border-radius: 100%;
    font-size: 12px;
    margin-left: -6px;
    margin-bottom: 10px;
}

nav .post_btn button {
    width: 139px;
    height: 40px;
}

nav ul {
    margin: 0px;
    padding: 0px;
}

nav ul li {
    list-style: none;
}

nav .search ul .search-item {
    float: left;
}

.bar__container {
    background: #fff;
    height: 48px;
    object-fit: contain;
    padding: 0 10px 0 10px;
    border-radius: 12px;
    box-shadow: 0px 5px 7px #0000001c;
}

nav .search select {
    height: 48px;
    outline: 0px;
    border: 0px;
    font-size: 16px;
    color: #333;
    padding: 0px 5px 0px 5px;
}

nav .search select option {
    outline: 0px;
    border-color: red;
}

nav .search input {
    outline: 0px;
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 48px;
    border: 0px;
    font-size: 16px;
    color: #333;
    padding: 0px 10px 0px 10px;
    border-left: 1px solid #e8e8e8;
}

.search__container {
    background: #3D7F39;
    top: calc(50% - 12.5px);
    right: 0;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 5px;
}


nav .location_img {
    position: relative;
}

nav .post_btn button {
    background-color: #fff;
    border: 0px;
    border-radius: 9px;
    color: #2E5F2B;
    font-weight: 600;
    cursor: pointer;
}

nav .post_btn {
    background: transparent linear-gradient(272deg, #112B5F 0%, #2E5F2B 49%, #F88B01 100%) 0% 0% no-repeat padding-box;
    padding: 3px;
    border-radius: 12px;
}

#Notification_scroll_bar::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: rgb(0 0 0 / 3%);
}

#Notification_scroll_bar::-webkit-scrollbar {
    width: 6px;
}

#Notification_scroll_bar::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgb(0 0 0 / 8%);
}

#Notification_scroll_bar a {
    color: #0d6efd !important;
}

/***********************      Home page CSS             ******************/

.home {
    margin-top: 148px;
}

/************* icon_grid ***************/

.home .icon_grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 20px;
    align-items: center;
    grid-template-rows: 100px;
}

.home .icon_grid .grid_item {
    text-align: center;
}

.home .icon_grid .grid_item .icon_title {
    font-size: 14px;
    color: #333;
    margin-top: 2px;
}

/*************** banner_grid ****************/

.home .banner_grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 20px;
    align-items: center;
}

.home .banner_grid .btn {
    margin-top: 10px;
    width: 200px;
}


/************ how investor **********/

.home .how_grid {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;
    align-items: center;
    margin-top: 50px;
    gap: 20px;
}

.home .how_grid .grid_investor {
    width: 579px;
    margin: 0px;
    padding: 0px;
}

.home .how_grid .grid_investor ul {
    font-size: 14px;
    margin: 0px;
    padding: 0px;
    list-style: none;
}

.home .how_grid .grid_investor li {
    margin-bottom: 32px;
    display: grid;
    grid-template-columns: auto auto;
}

.how_grid .grid_investor li .item {
    padding: 5px;
}

.how_grid .grid_investor li .icon img {
    padding: 10px;
    border-radius: 12px;
    background-color: #E4F1E3;
    width: 40px;
    height: 40px;
}

/******************* Slider  ***************/

.home .slider {
    display: grid;
    align-items: center;
    margin-top: 10px;
}

.home .slider .view {
    margin-left: 10px;
    color: #112B5F;
    font-weight: 600;
}

.home .slider .slider_btn {
    float: right;
}

.home .slider .slider_btn img {
    background-color: #D4E8D3;
    border-radius: 4px;
    padding: 5px 8px;
    margin: 2px;
    cursor: pointer;
}

.cards_container {
    background-color: #E8E8E8;
    border-radius: 12px;
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    overflow: hidden;
}

.cards_container .card {
    align-items: center;
    font-family: 'SF Pro Display', sans-serif;
}

.card_container {
    background-color: #fff;
    padding: 10px;
    border-radius: 12px;
    box-shadow: 0px 3px 6px #00000029;
    width: 100%;
}

.cards_container .card_container .pic {
    width: 84px;
    height: 84px;
}


/******************Investor Slider***************/
.investor-cards .grid {
    display: grid;
    grid-template-columns: 84px auto;
    align-items: flex-start;
}

.investor-cards .grid .pic {
    width: 84px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.investor-cards .grid .content {
    margin-left: 16px;
    text-align: left;
}

.investor-cards .card_container button {
    width: 100%;
    padding: 5px 0px;
    background-color: #2E5F2B;
    color: white;
    border: 0px;
    outline: 0px;
    border-radius: 8px;
    margin-top: 4px;
}


.investor-cards .card_container .name {
    font-weight: 600;
    font-size: 16px;
    color: #333333;
}

.investor-cards .card_container .name span{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.investor-cards .card_container .name .verified {
    width: 14px;
}

.investor-cards .card_container .location {
    color: #757575;
    font-size: 12px;
    margin-top: 3px;
    width: 190px;
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
}

.investor-cards .card_container .location img {
    width: 9px;
}

.investor-cards .card_container .budget {
    font-size: 14px;
    font-weight: 600;
    margin-top: 12px;
    color: #333333;
    margin-bottom: 3px;
}

.investor-cards .card_container .invested {
    font-size: 12px;
    margin-top: 16px;
}

.investor-cards .card .testimonial {
    font-size: 13px;
    margin-top: 4px;
    color: #757575;
}

.investor-cards .card .testimonial .verified {
    width: 12px;
}

.investor-cards .card .testimonial {
    font-size: 12px;
    border: 1px solid #ccc;
    padding: 2px 5px;
    border-radius: 50px;
}

.investor-cards .card .profile_type {
    font-size: 12px;
}

/*************Farmer Card *************/

.home .farmer_slider .card_container {
    text-align: center;
}

.home .farmer_slider .card_container hr {
    border: 1px solid #E8E8E8;
    width: 80%;
}

.home .farmer_slider .card_container .title {
    font-weight: 600;
    font-size: 16px;
    color: #333333;
    margin-bottom: 5px;
    margin-top: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    max-width: 280px;
}

.home .farmer_slider .pic {
    width: 84px;
    height: 84px;
    border-radius: 5px;
}

.home .farmer_slider .location {
    font-size: 12px;
    color: #757575;
}

.home .farmer_slider .budget {
    font-size: 14px;
    color: #333333;
    font-weight: 600;
    margin-bottom: 5px;
}

.home .farmer_slider .amt-for {
    font-size: 14px;
    color: #333333;
    margin-bottom: 8px;
}

.home .farmer_slider .approved {
    font-size: 12px;
    color: #757575;
    margin-bottom: 12px;
}

.home .farmer_slider .connect {
    bottom: 0px;
    background-color: #3D7F39;
    color: #fff;
    margin: 0px;
    padding: 5px 0px;
    position: relative;
    bottom: 0px;
    border: 0px;
    outline: 0px;
    width: 100%;
    margin-top: -3px;
    border-radius: 8px;
}

.home .farmer_slider .kyc {
    float: right;
}


/*******************Post Slider  ***************/

.post-cards .card_container .title {
    font-weight: 600;
    font-size: 16px;
    color: #333333;
    margin-bottom: 0px;
    height: 24px;
    overflow: hidden;
}

.post-cards .card_container .posted_on {
    font-size: 12px;
    margin-right: 5px;
    float: right;
    font-weight: 200;
}

.post-cards .card_container .bookmark {
    cursor: pointer;
    float: right;
    border-radius: 100%;
}

.post-cards .card_container .location {
    font-size: 12px;
    margin-bottom: 5px;
    height: 18px;
    overflow: hidden;
}

.post-cards .card_container .budget {
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 10px;
}

.post-cards .card_container .description {
    font-size: 14px;
    margin-bottom: 10px;
    height: 60px;
    overflow: hidden;
    word-break: break-all;
}

.post-cards .card_container .foot {
    font-size: 12px;
    margin-bottom: 15px;
}

.post-cards .card_container .foot .profile {
    float: right;
}

.post-cards .card_container .foot .profile img {
    vertical-align: middle;
}

.post-cards .card_container .foot .profile img {
    width: 20px;
    height: 20px;
    margin: 0px;
    padding: 0px;
    border: 1px solid #ccc;
    border-radius: 100%;
    background-color: #ccc;
}

.post-cards .card_container .btn_full {
    width: 100%;
    background-color: #3D7F39;
    border: 0px;
    outline: 0px;
    color: #fff;
    border-radius: 8px;
    font-size: 16px;
    padding: 5px;
}

/********** bottom banner ****************/

.bottom_banner {
    background-image: url('./images/footer_banner.png');
    background-color: #333;
    height: 300px;
    width: 100%;
    border-radius: 24px;
    margin-top: 50px;
    color: #fff;
    font-family: 'SF Pro Display', sans-serif;
}

.bottom_banner .overlay {
    position: absolute;
    background-color: #000;
    opacity: 0.6;
    width: 100%;
    height: 300px;
    border-radius: 24px;
    z-index: 0;
}

.bottom_banner .content {
    position: absolute;
    width: 100%;
    text-align: center;
    margin-top: 47px;
    color: #fff;
}

.bottom_banner .content h1 {
    color: #fff;
}

.bottom_banner .content h4 img {
    width: 17px;
    margin-left: 12px;
    vertical-align: middle;
    margin-top: -3px;
}

.bottom_banner .content button {
    background-color: #4C9F47;
    color: #fff;
    border: 0px;
    margin: 15px;
    padding: 10px 70px;
    border-radius: 12px;
}

/*********** footer ************/
.navbar__link--active {
    font-weight: 700;
    color: #0b4a02 !important;
    transition: border-bottom .5s ease-in-out;
}

footer {
    width: 100%;
    margin-top: 50px;
    background-color: #E4F1E3;
    padding: 0px;
    font-size: 13px;
}


footer .container .social {
    margin-top: 10px;
}

footer .container .social img {
    margin-right: 10px;
    width: 30px;
}

footer .container .col {
    padding: 25px;
}

footer .container h5 {
    margin-bottom: 0px;
    font-size: 13px;
}

footer .container ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
    margin-top: 12px;
}

footer .container ul li {
    padding: 3px 0px;
}

/*********************Profile Page *******************/

.profile-grid {
    position: relative;
    display: grid;
    grid-template-columns: auto auto;
    gap: 28px;
    margin-bottom: 50px;
}

.profile-grid .about-grid {
    padding: 5px;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    gap: 28px;
}

.profile-grid .about-grid .type {
    float: right;
    font-size: 12px;
    color: #333;
    border: 1px solid #333;
    font-weight: 400;
    padding: 2px 6px;
    border-radius: 100px;
}

.profile-grid .about-grid .pic {
    width: 178px;
}

.profile-grid .about-grid .content {
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid rgb(240, 240, 240);
    padding: 10px;
    background-color: #fff;
    border-radius: 12px;
    font-size: 14px;
    min-width: 740px;
}

.profile-grid .content .about {
    margin-top: 15px;
    margin-bottom: 15px;
}

.profile-grid .content .info ul {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: 70px 70px 70px;
    margin: 0px;
    padding: 0px;
    list-style: none;
}

.profile-grid .content .interest {
    margin-top: 10px;
}

.profile-grid .about-grid h4 {
    color: #333333;
    font-size: 16px;
}

.profile-grid .about-grid .name img {
    width: 12px;
    vertical-align: middle;
}

.profile-grid .about-grid .interest .tag-grp {
    margin: 0px;
    padding: 0px;
    list-style: none;
    font-size: 14px;
}

.profile-grid .interest .tag-grp li {
    float: left;
    margin-right: 20px;
}

.profile-grid .interest .tag-grp li img {
    width: 12px;
    vertical-align: middle;
}

.profile-grid .r-sidebar {
    width: 250px;
    padding-top: 3px;
}

.profile-grid .r-sidebar button {
    width: 100%;
    outline: 0px;
    border: 0px;
    height: 45px;
    margin: 3px;
    border-radius: 4px;
    font-size: 16px;
    color: #fff;
}

.profile-grid .r-sidebar .follow-btn {
    background-color: #112B5F;
}

.profile-grid .r-sidebar .connect-btn {
    background-color: #2E5F2B;
}

.profile-grid .r-sidebar .scores {
    margin-top: 0px;
    background-color: #E8E8E8;
    border-radius: 12px;
    padding: 16px 12px 1px 12px;
    text-align: center;
}

.profile-grid .r-sidebar .scores .box {
    background-color: #FFFFFF;
    border-radius: 12px;
    padding: 12px;
    margin-bottom: 12px;
}

.profile-grid .r-sidebar .scores .box .title {
    font-weight: 600;
    font-size: 15px;
    color: #333333;
    margin-bottom: 3px;
}

.profile-grid .r-sidebar .scores .box .small {
    font-size: 12px;
    color: #333333;
}

.profile-grid .r-sidebar .score {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 3px;
}

.profile-grid .r-sidebar .scores .active-investment {
    margin-bottom: 0px;
}

.profile-grid .name {
    color: #333333;
}

.profile-grid .location {
    font-size: 12px;
}

.profile-grid .tabs {
    font-size: 14px;
}

.profile-grid .tab-button .title {
    cursor: pointer;
    font-size: 15px;
    margin-right: 30px;
    float: left;
}

.profile-grid .tab-button .active {
    color: #112B5F;
    font-weight: 600;
    border-bottom: 1px solid #112B5F;
    padding-bottom: 6px;
}

.profile-grid .tabs .tab {
    position: relative;
    left: 0px;
    display: none;
    background-color: #FAFAFA;
    float: left;
    padding-top: 20px;
    border-top: 1px solid #ccc;
    width: 100%;
}

.profile-grid .tabs .active {
    display: block;
}

.profile-grid .tabs .related-profile h3 {
    color: #333;
}

.related-profile h3 .view {
    font-size: 14px;
    color: #112B5F;
    float: right;
    font-weight: 500;
}


/**************************Review Tabs**********************************/

.review-tabs {
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 12px;
    padding: 24px;
}

.review-tabs .title {
    font-size: 18px;
    font-weight: 600;
    color: #333333;
    font-family: 'SF Pro Display', sans-serif;
}

.review-tabs .write-review {
    cursor: pointer;
    margin-left: 650px;
    color: #112B5F;
    font-size: 14px;
    font-weight: 600;
}

.review-tabs .rating {
    font-family: 'SF Pro Display', sans-serif;
    margin-top: 16px;
    font-size: 40px;
    letter-spacing: 0.96px;
    color: #3D7F39;
}

.star-img {
    width: 16px;
    margin-bottom: 8px;
    margin-left: 8px;
}

.review-tabs .grid {
    display: grid;
    grid-template-columns: 0.16fr 1fr;
}

.review-tabs .rating-left {
    border-right: 1px solid #E8E8E8;
}

.review-tabs .star-reviews {
    padding-left: 80px;
    padding-top: 20px;
}

.review-tabs .star-1 {
    color: #333333;
}

.review-tabs .review-rating {
    font-size: 14px;
    font-family: 'SF Pro Display', sans-serif;
    margin-top: 12px;
    letter-spacing: 0.34px;
    color: #333333;
}

.review-tabs .review-rating::after {
    content: "\a";
    white-space: pre;
}

.horizontal-line {
    margin-top: 30px;
    border: 1px solid #E8E8E8;
}

.review-tabs .reviews-title {
    margin-top: 16px;
    font-size: 18px;
    font-family: 'SF Pro Display', sans-serif;
    font-weight: 600;
    letter-spacing: 0.43px;
    color: #333333;
}


.review-tabs .top-reviews {
    cursor: pointer;
    margin-left: 670px;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #E8E8E8;
    background-color: #E8E8E8;
    border-radius: 4px;
    padding: 8px;
}

.review-tabs .review-card {
    margin-top: 20px;
    border: 1px solid #E8E8E8;
    border-radius: 12px;
    padding: 15px;
}

.review-tabs .review-card1 {
    margin-top: 12px;
    border: 1px solid #E8E8E8;
    border-radius: 12px;
    padding: 15px;
}

.review-tabs .star-rating {
    color: #FFFFFF;
    border-radius: 4px;
    padding: 4px 8px;
}

.review-tabs .star-rating-title {
    font-weight: bold;
    font-size: 14px;
    margin-left: 15px;
    color: #333333;
    font-family: 'SF Pro Display', sans-serif;
}

.review-star {
    margin-left: 5px;
    margin-bottom: 1px;
}

.review-tabs .star {
    margin-left: 5px;
    width: 9px;
    background-color: #FFFFFF;
}

.review-tabs .review {
    margin-top: 10px;
    font-size: 14px;
    font-family: 'SF Pro Display', sans-serif;
    color: #333333;
    margin-bottom: 14px;
}

.review-tabs .reviewer {
    font-size: 12px;
    letter-spacing: 0.29px;
    color: #646464;
}

.check-mark {
    width: 12px;
    margin-left: 24px;
}

.review-tabs .certified {
    font-size: 12px;
    letter-spacing: 0.29px;
    color: #646464;
    margin-left: 8px;
}

.review-tabs .load-more {
    cursor: pointer;
    margin-top: 12px;
    color: #112B5F;
    font-size: 14px;
    font-weight: 600;
}

.side {
    float: left;
    width: 15%;
    margin-top: 10px;
}

.middle {
    margin-top: 15px;
    float: left;
    width: 58%;
}

/* Place text to the right */
.right {
    text-align: right;
    float: right;
}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

.bar-container {
    width: 100%;
    background-color: #f1f1f1;
    text-align: center;
    color: white;
}

/* Individual bars */
.bar-5 {
    height: 7px;
    background-color: #04AA6D;
    border-radius: 10px
}

.bar-4 {
    height: 7px;
    background-color: #2196F3;
    border-radius: 10px
}

.bar-3 {
    height: 7px;
    background-color: #00bcd4;
    border-radius: 10px
}

.bar-2 {
    height: 7px;
    background-color: #ff9800;
    border-radius: 10px
}

.bar-1 {
    height: 7px;
    background-color: #f44336;
    border-radius: 10px
}

input[disabled] {
    background: rgb(0 29 85 / 14%);
}

@media (max-width: 400px) {

    .side,
    .middle {
        width: 100%;
    }

    .right {
        display: none;
    }
}

/************** Post your need *************/

.post-need {
    position: relative;
    margin: auto;
    width: 800px;
    left: 0px;
    right: 0px;
    margin-top: 200px;
    text-align: center;
    min-height: 400px;
}

.post-need h3 {
    font-size: 24px;
    color: #333333;
    text-align: center;
}

.post-need .title img {
    width: 10px;
    vertical-align: middle;
}


.post-need p {
    color: #333;
    text-align: center;
}

.post-need button {
    margin: 12px;
    margin-top: 40px;
    padding: 30px 50px;
    outline: 0px;
    background-color: #fff;
    border: 2px solid #757575;
    border-radius: 12px;
    color: #757575;
    text-align: left;
}

.post-need button:hover {
    box-shadow: 0px 9px 23px #00000029;
    border: 2px solid #2E5F2B;
}

.post-need button:hover>.title {
    color: #2E5F2B;
}

.post-need button:hover>.small {
    color: #2E5F2B;
}

.post-need button .title {
    font-size: 24px;
    margin-bottom: 5px;
}

/************* post form **************/

.post-form {
    position: relative;
    width: 926px;
    margin: auto;
    margin-top: 130px;
    box-shadow: 0px 9px 23px #00000029;
    border: 1px solid #E8E8E8;
    border-radius: 12px;
    background-color: #fff;
}

.post-form .head {
    padding: 30px 40px;
    border-bottom: 1px solid #E8E8E8;
}

.post-form .title {
    font-size: 20px;
    color: #333333;
    font-weight: 600;
    margin-bottom: 8px;
}

.post-form .small {
    font-size: 14px;
    color: #333333;
}

.post-form .form-grid {
    display: grid;
    grid-template-columns: auto auto;
    padding: 30px 40px;
    color: #333333;
}

.post-form .form-grid input {
    width: 480px;
}

/* .post-form .form-grid .field {
    margin-bottom: 30px;
} */

.post-form .form-grid .field label {
    font-size: 14px;
}

.post-form .form-grid .field input {
    /* margin-top: 10px; */
    width: 480px;
    height: 45px;
    font-size: 14px;
    border-radius: 8px;
    outline: 0px;
    border: 1px solid #333;
    padding-left: 10px;
}

.post-form .form-grid .field input:focus,
.post-form .form-grid .field textarea:focus,
.post-form .form-grid .field select:focus {
    border-color: #4c9f48
}

.post-form .form-grid .field textarea {
    /* margin-top: 10px; */
    width: 480px;
    height: 150px;
    font-size: 14px;
    border-radius: 8px;
    outline: 0px;
    border: 1px solid #333;
    padding: 10px;
}

.post-form .form-grid .field select {
    /* margin-top: 10px; */
    width: 490px;
    height: 45px;
    font-size: 14px;
    border-radius: 8px;
    outline: 0px;
    border: 1px solid #333;
    padding: 10px;
}

.post-form .form-grid .submit {
    background-color: #2E5F2B;
    outline: none;
    border: 0px;
    color: #fff;
    padding: 14px;
    border-radius: 8px;
}

.post-form .form-grid .upload-btn {
    margin-top: 30px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border: 2px dashed #112B5F;
    background-color: #E4F1E3;
}

.post-form .form-grid .upload {
    text-align: center;
}

/********** Post View ************/

.post-view-grid {
    display: grid;
    grid-template-columns: 200px 700px 300px;
    gap: 20px;
}

.post-view-grid .description {
    text-align: left;
    padding: 5px;
    text-align: justify;
}

.post-view-grid .details .cover {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 12px;
    border: 1px solid #00000029;
    padding: 20px;
    background-color: #fff;
}

.post-view-grid .abt-grid {
    display: grid;
    grid-template-columns: auto auto;
}

.post-view-grid .abt-grid .pic {
    width: 40px;
    vertical-align: middle;
}

.post-view-grid .abt-grid .info .name {
    color: #333;
    font-size: 16px;
    font-weight: 600;
}

.post-view-grid .abt-grid .info .location {
    font-size: 12px;
    color: #646464;
    margin-top: 3px;
}

.post-view-grid .view-profile {
    color: #112B5F;
    margin-top: 20px;
    border: 1px solid #112B5F;
    text-align: center;
    width: 178px;
    padding: 6px 0px;
    border-radius: 4px;
}

.post-view-grid .box {
    margin-top: 20px;
    border: 1px solid #E8E8E8;
    text-align: center;
    width: 100%;
    padding: 6px 0px;
    border-radius: 4px;
    overflow: hidden;
    background-color:#fff;
}

.post-view-grid .map .title {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    text-align: left;
    margin-left: 20px;
}

.post-view-grid .map .location {
    font-size: 12px;
    text-align: left;
    margin-left: 20px;
    margin-bottom: 8px;
}


.post-view-grid .post-inquiry {
    margin-top: 20px;
    border: 1px solid #ccc;
    text-align: center;
    width: 178px;
    padding: 6px 0px;
    border-radius: 4px;
}

.post-view-grid .budget {
    border: 1px solid #E8E8E8;
    text-align: center;
    width: 300px;
    padding: 16px 0px;
    margin-top: 0px;
}

.post-view-grid .budget .title {
    font-size: 16px;
    font-weight: 500;
    color: #333;
}

.post-view-grid .budget .amt {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    margin-top: 8px;
}

.post-view-grid .inquire {
    margin-top: 16px;
    border-radius: 4px;
    color: #fff;
    background-color: #112B5F;
    width: 300px;
    text-align: center;
    padding: 10px 0px;
    font-size: 16px;
    border: 0px;
    outline: 0px;
}

.post-view-grid .connect {
    margin-top: 16px;
    border-radius: 4px;
    color: #fff;
    border: 1px solid #112B5F;
    width: 300px;
    text-align: center;
    padding: 8px 0px;
    font-size: 16px;
    outline: 0px;
}

.post-view-grid .details h4 {
    color: #333333;
}

.post-view-grid .details h4 .right {
    float: right;
    font-size: 12px;
    color: #646464;
    font-weight: 300;
}

.post-view-grid .details h4 .right span {
    margin-left: 10px;
}

.post-view-grid .details .report {
    color: #112B5F;
    font-size: 12px;
    float: right;
}

.post-view-grid .details .title {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin-top: 28px;
}

.post-view-grid .tag-grp {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

.post-view-grid .tag-grp .tag {
    float: left;
    margin: 5px 20px 5px 0px;
}

.post-view-grid .tag-grp li img {
    width: 12px;
}

.post-view-grid .villages {
    margin-top: 20px;
}

.post-view-grid .crop {
    margin-top: 20px;
}

.post-view-grid .crop .crops {
    margin-top: 5px;
}

.post-view-grid .details .ad {
    background-color: #E8E8E8;
    padding: 1px 4px;
    border-radius: 5px;
    font-size: 12px;
    color: #646464;
    font-weight: 200;
}

.post-view-grid .details .posted-on img {
    vertical-align: middle;
    width: 12px;
}

.post-view-grid .details .posted-on {
    vertical-align: top;
}

.post-view-grid .details .bookmark img {
    cursor: pointer;
    vertical-align: bottom;
    width: 10px;
}

.post-view-grid .crop-details .crop-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 12px;
    border-radius: 8px;
}

.post-view-grid .crop-grid .box .head {
    font-size: 14px;
    color: #333333;
    font-weight: 600;
    text-align: left;
    margin: 10px 10px 5px 10px;

}

.post-view-grid .crop-grid .box .sel {
    font-size: 12px;
    color: #3D7F39;
    text-align: left;
    margin: 5px 10px 10px 10px;
    font-weight: 600;
}

.post-view-grid .budget-details .return {
    margin: 10px;
    text-align: left;
}

.post-view-grid .budget-details .title {
    border-bottom: 1px solid #ccc;
    padding: 6px 0px;
    font-size: 12px;
}

.post-view-grid .budget-details .ans {
    margin-top: 5px;
    font-weight: 600;
    font-size: 14px;
}

.gallery-container {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 12px;
    padding: 20px;
    background-color: #FFFFFF;
    margin-top: 20px;
}

.gallery .slider {
    margin-top: 8px;
    padding-left: 10px;

}

.gallery .slider .img {
    padding-right: 20px;

}

.gallery .slider .load-more {
    cursor: pointer;
    margin-top: 10px;
    color: #112B5F;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.38px;
}

.post-view .similar-posts {
    margin-top: 30px;
}

.post-view .similar-posts h4 .right {
    float: right;
    font-size: 14px;
}


/***********************************PopUp Box***************************************/

.popup-box-container {
    overflow-y: scroll;
    position: fixed;
    background: #000000CC;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin-top: 30px;
    z-index: 1;
}

.popup-box {
    width: 55%;
    margin: 0 auto;
    height: auto;
    margin-top: calc(15vh - 30px);
    background: #fff;
    border-radius: 24px;
    padding: 24px;
    border: 1px solid #999;
    position: relative;
}

.close-icon img {
    cursor: pointer;
    position: absolute;
    right: 12px;
    top: 12px;
    width: 25px;
    height: 25px;
}

.report {
    cursor: pointer;
    text-align: right;
    margin-top: 24px;
    color: #112B5F;
    font-size: 14px;
    font-weight: 600;
}

.report-heading {
    font-size: 24px;
    font-weight: 600;
    color: #333333;
}

.report-text {
    margin-top: 8px;
    color: #333333;
    font-size: 16px;
}

.line {
    border: 1px solid #E8E8E8;
}

.content-title {
    margin-top: 15px;
    font-size: 18px;
    font-weight: 600;
    color: #333333;
    letter-spacing: 0.43px;
}

.category-list {
    margin: 0px;
    padding: 0px;
    list-style: none;
    margin-top: 12px;
    font-size: 14px;
}

.category-list .check-box {
    border-radius: 100%;
    cursor: pointer;
}

.category-list ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
    margin-top: 12px;
}

.category-list ul li {
    margin-bottom: 14px;
    display: inline;
    padding-right: 77px;
    font-size: 14px;
    color: #333333;
}

.description-container {
    margin-top: 12px;
}

.description-container textarea {
    font-size: 14px;
    font-family: 'SF Pro Display', sans-serif;
    width: 100%;
    height: 80px;
    border-radius: 8px;
    outline: 0px;
    border: 1px solid #333333;
    padding: 10px;
}

.upload-image-container {
    display: flex;
}

.upload-image {
    border: 1px dashed #3D7F39;
    background-color: #E4F1E3;
    cursor: pointer;
    margin-top: 12px;
    margin-bottom: 12px;
    padding: 30px;
    border-radius: 8px;
    text-align: center;
}

.upload-image .title {
    font-size: 16px;
    color: #001D55;
}

.upload-image .max-size {
    font-size: 12px;
    color: #646464;
    letter-spacing: 0.29px;
}

.add-more {
    cursor: pointer;
    background-color: #E4F1E3;
    font-size: 16px;
    color: #001D55;
    margin-top: 12px;
    margin-left: 12px;
    margin-bottom: 12px;
    padding: 30px;
    border-radius: 8px;
    border: 1px dashed #3D7F39;
    text-align: center;
}

.add-more img {
    margin-top: 4px;
}

.popup-box .button {
    cursor: pointer;
    border: 1px solid #3D7F39;
    width: 64px;
    padding: 16px;
    text-align: center;
    border-radius: 12px;
    font-size: 14px;
    background-color: #3D7F39;
    color: #FFFFFF;
}

.gallery .title {
    margin-top: 28px;
    font-size: 24px;
    font-weight: 600;
    color: #333333;
    letter-spacing: 0.58px;
}

**************Search Results *************** .search-page {
    margin-top: 120px;
    display: grid;
    grid-template-columns: 260px auto;
}

.search-page .filters {
    border-right: 1px solid #ccc;
    padding-right: 30px;
}

.search-page .filters .head-title {
    font-size: 20px;
    color: #333333;
}

.search-page .filters .head .right {
    float: right;
    color: #3D7F39;
    font-size: 14px;
}

.search-page .filters .dropdown {
    margin-top: 28px;
    border-bottom: 1px solid #E8E8E8;
    padding-bottom: 8px;
}

.search-page .filters .dropdown .title {
    font-size: 16px;
    color: #333;
}

.search-page .filters .dropdown img {
    float: right;
    width: 14px;
    margin-top: 4px;
}

.search-page .filters .cat-list ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
    margin-top: 12px;
    font-size: 14px;
}

.search-page .filters .cat-list ul li {
    margin-bottom: 14px;
    width: 250px;
    overflow: hidden;
}

.search-page .filters .cat-list ul li input {
    margin-right: 5px;
}

.search-page .filters .cat-list .price-list {
    width: 100%;
    border: 3px solid #112B5F;
}

.search-page .filters .budget .price-slider {
    width: 100%;
    background-color: #3D7F39;
    height: 4px;
    margin-top: 6px;
}

.search-page .filters .budget .min {
    border: 1px solid #707070;
    border-radius: 100px;
    width: 15px;
    height: 15px;
    margin-top: -10px;
    float: left;
    background-color: #fff;
}

.search-page .filters .budget .max {
    border: 1px solid #707070;
    border-radius: 100px;
    width: 15px;
    height: 15px;
    float: right;
    margin-top: -10px;
    background-color: #fff;
}

.search-page .filters .budget .txt {
    font-size: 12px;
    margin-top: 18px;
    margin-bottom: 10px;
}

.search-page .filters .budget .right {
    float: right;
}

.search-page .filters .budget .price {
    margin-top: 10px;
    font-size: 12px;
    color: #333333;
    font-weight: 600;
}

.search-page .search-result .header {
    border-bottom: 1px solid #E8E8E8;
    padding-bottom: 20px;
}

.search-page .search-result .header .title {
    font-size: 20px;
    font-weight: 600;
}

.search-page .search-result {
    padding: 30px 50px;
}

.search-page .search-result .header .sort {
    float: right;
}

/*******************************Search Card***********************************/

.search_cards_container {
    background-color: #E4F1E3;
    border-radius: 24px;
    padding: 12px;
    height: max-content;
    margin-bottom:16px;
}

.search_cards_container .grid-container {
    display: grid;
    grid-template-columns: 670px 1fr;
    grid-gap: 20px;
    font-family: 'SF Pro Display', sans-serif;
}

.search_cards_container .card {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 3px 6px #00000029;
    min-height: 120px;
    padding: 12px;
}

.search_cards_container .grid {
    /* display: grid;
    grid-template-columns: 84px auto;
    align-items:flex-start; */

    align-items: flex-start;

}

.search_cards_container .img {
    cursor: pointer;
    width: 100%;
    display: none;
}

.search_cards_container .deal-betn .image img {
    width: 60px;
    height: 60px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.search_cards_container .deal-betn {
    margin-top: 20px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 20px;
}

.search_cards_container .deal-betn .user {
    float: left;
    display: grid;
    grid-template-columns: auto auto;
    padding-right: 20px;
    border-right: 1px solid #ccc;
}

.search_cards_container .deal-betn .amt {
    border-left: 1px solid #ccc;
    padding-left: 20px;
}

.search_cards_container .deal-betn .user h4 {
    font-size: 16px;
}

.search_cards_container .profile {
    font-weight: 600;
    float: right;
}

.search_cards_container .ftr .pic {
    width: 25px;
    height: 25px;
    border-radius: 5px;
}

.search_cards_container .name {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
}

.search_cards_container .project {
    font-weight: 600;
    font-size: 12px;
    color: #757575;
    margin-top: 5px;
}

.search_cards_container .card .right {
    float: right;
}

.search_cards_container .time {
    font-size: 12px;
}

.search_cards_container .card .time img {
    width: 10px;
}

.search_cards_container .location {
    font-size: 12px;
}

.search_cards_container .location img {
    width: 9px;
    vertical-align: inherit;
}

.search_cards_container .bookmark img {
    cursor: pointer;
    width: 18px;
    float: right;

}

.search_cards_container .rating {
    border: 1px solid #3D7F39;
    background-color: #3D7F39;
    font-size: 12px;
    font-weight: bold;
    padding: 2px 5px;
    color: #FFFFFF;
    border-radius: 4px;
}

.search_cards_container .review {
    font-weight: bold;
    font-size: 12px;
    color: #757575;
    margin-top: 5px;
}

.search_cards_container .star {
    font-size: 12px;
    font-weight: 600;
    color: #333;
}

.search_cards_container .star img {
    width: 9px;
    margin-left: 2px;
    margin-top: 5px;
}

.search_cards_container .about {
    font-weight: 600;
    font-size: 14px;
    color: #333333;
    margin-top: 8px;
}

.search_cards_container .aboutmore {
    font-size: 14px;
    color: #333333;
    height: 45px;
    overflow: hidden;

}

.search_cards_container .ftr .postid {
    font-size: 12px;
}

/* .search_cards_container .card .read-more{
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    color: #112B5F;
    float: right;
} */

.search_cards_container .card1_container {
    background-color: #fff;
    padding: 12px;
    display: grid;
    border-radius: 12px;
    box-shadow: 0px 3px 6px #00000029;
    text-align: center;
    justify-content: center;
}

.search_cards_container .card-1 {
    font-family: 'SF Pro Display', sans-serif;
}

.search_cards_container .card-1 .investment-budget {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #333333;

}

.search_cards_container .card-1 .budget {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #333333;
    margin-top: 10px;
}

.search_cards_container .card-1 .offer {
    background-color: #112B5F;
    font-weight: bold;
    border: 1px solid #112B5F;
    color: #fff;
    border-radius: 4px;
    margin-top: 10px;
    width: 140px;
    padding: 5px;
    font-size: 14px;
}

.search_cards_container .card-1 .connect {
    margin-top: 10px;
    font-weight: bold;
    border: 1px solid #112B5F;
    color: #112B5F;
    background-color: #FFFFFF;
    border-radius: 4px;
    width: 140px;
    padding: 5px;
    font-size: 14px;
}

/*******************************Cards for Farmers and Investors*******************************/
.post_cards_container {
    background-color: #E4F1E3;
    border-radius: 24px;
    margin-top: 24px;
    padding: 12px;
    height: max-content;
}

.post_cards_container .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.post_cards_container .card {
    font-family: 'SF Pro Display', sans-serif;
    width: 670px;
}

.post_cards_container .card_container {
    background-color: #fff;
    padding: 12px;
    border-radius: 12px;
    box-shadow: 0px 3px 6px #00000029;
}

.post_cards_container .ad {
    border-radius: 4px;
    padding: 2px 4px;
    background-color: #E8E8E8;
    color: #333333;
    font-weight: 600;
}

.post_cards_container .grid .content {
    margin-left: 70px;
}

.post_cards_container .card .name {
    margin-left: 4px;
    font-weight: bold;
    font-size: 16px;
    color: #333333;
}

.post_cards_container .card .time img {
    width: 9px;
    margin-left: 105px;
}

.post_cards_container .card .time {
    font-size: 12px;
    margin-right: 12px;
}

.post_cards_container .card .location {
    font-size: 12px;
    margin-right: 12px;
}

.post_cards_container .card .location img {
    width: 9px;
}

.post_cards_container .card .bookmark {
    cursor: pointer;
    width: 9px;
    float: right;
    border: 1px solid #757575;
    padding: 0px 7px;
    border-radius: 100%;
}

.post_cards_container .card .description-title {
    font-weight: 600;
    font-size: 14px;
    margin-top: 10px;
    color: #333333;
}

.post_cards_container .card .description {
    font-size: 14px;
    color: #333333;
    margin-top: 4px;
}

.post_cards_container .card .read-more {
    float: right;
    font-size: 12px;
    font-weight: bold;
    color: #112B5F;
}

.post_cards_container .card .read-more {
    font-size: 12px;
}

.post_cards_container .card .return {
    font-size: 14px;
    color: #333333;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 8px;
}

.post_cards_container .card .return-type {
    font-size: 14px;
    color: #333333;
    margin-left: 8px;
    background-color: #E8E8E8;
    border-radius: 12px;
    padding: 4px 12px;

}

.post_cards_container .card .profile {
    margin-left: 350px;
}

.post_cards_container .card .profile-name {
    vertical-align: middle;
    margin-left: 8px;
    font-size: 12px;
    font-weight: bold;
    color: #333333;
}

.post_cards_container .card .profile img {
    width: 20px;
}

.post_cards_container .card .profile .verified {
    margin-left: 4px;
}

.form-control {
    height: 35px;
}

.form-control:focus {
    /* color: #212529;
        background-color: #fff;
        padding: 12px;
        width: 180px;
        display: grid; */
    /* border-radius: 12px; */
    box-shadow: none;
    border-color: #4c9f48;
    /* justify-content: center; */
}

.post_cards_container .card-1 {
    font-family: 'SF Pro Display', sans-serif;
}

.post_cards_container .card-1 .investment-budget {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #333333;
}

.post_cards_container .card-1 .budget {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #333333;
    margin-top: 17px;
}

.post_cards_container .card-1 .offer {
    background-color: #112B5F;
    font-weight: bold;
    border: 1px;
    color: #fff;
    padding: 4px 36px 4px 36px;
    border-radius: 4px;
    margin-top: 28px;
    margin-left: 35px;
    margin-right: 35px;
}

.post_cards_container .card-1 .connect {
    margin-top: 8px;
    margin-left: 35px;
    margin-right: 35px;
    font-weight: bold;
    border: 1px solid #112B5F;
    color: #112B5F;
    background-color: #FFFFFF;
    padding: 4px 46px 4px 46px;
    border-radius: 4px;
}

.Toastify__close-button--light {
    flex: 0;
}

/**********************Settings**************************/

.settings {
    margin-top: 88px;
    display: grid;
    grid-template-columns: 310px auto;
}

.settings-tab-section {
    background-color: #FFFFFF;
    box-shadow: 0px 4px 6px #0000000A;
    display: grid;
    grid-template-rows: 60px auto;
    width: 285px;
    padding-top: 32px;
    padding-left: 60px;
    padding-right: 12px;
}

.settings-tab-section .profile img {
    width: 40px;
}

.settings-tab-section .profile {
    display: grid;
    grid-template-columns: 0.3fr 1fr;
    border-bottom: 1px solid #E8E8E8;
}

.settings-tab-section .profile .pic {
    width: 40px;
    vertical-align: middle;
    border-radius: 8px;
}

.settings-tab-section .profile .name {
    margin-top: 3px;
    color: #333;
    font-size: 16px;
    font-weight: bold;
}

.settings-tab-section .profile .name img {
    width: 12px;
}

.settings-tab-section .profile .info .location {
    font-size: 12px;
    color: #646464;
}

.tab-items {
    cursor: pointer;
    margin-top: 25px;
    color: #757575;
    font-weight: 600;
    font-size: 14px;
}

.tab-list .active {
    cursor: pointer;
    margin-top: 25px;
    color: #333333;
    font-weight: 600;
    font-size: 14px;
}

.item-name {
    margin-left: 12px;
}

.settings .tab {
    position: relative;
    display: none;
    width: 100%;
}

.settings .active {
    display: block;
}

.settings-container {
    padding: 35px 120px 0px 84px;
}

.settings-container .title {
    font-size: 16px;
    color: #757575;
}

.settings-container .form-check-input1 {
    margin-left: 50px;
}

.settings-container .caption {
    margin-top: 10px;
    border-bottom: 1px solid #E8E8E8;
    padding-bottom: 16px;
    font-size: 14px;
    color: #333333;
}

.settings-container .label {
    font-size: 16px;
    color: #333333;
    margin-top: 20px;
    margin-bottom: 12px;
}

.settings-container .label-star {
    color: #D92121;
    margin-left: 5px;
}

.settings-container .label-caption {
    font-size: 12px;
    color: #646464;
}

.settings-container .about-field {
    width: 810px;
}

.settings-container .about-field textarea {
    font-size: 14px;
    font-family: 'SF Pro Display', sans-serif;
    width: 97%;
    height: 124px;
    border-radius: 8px;
    outline: 0px;
    border: 1px solid #333333;
    padding: 10px;
}

.settings-container input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


.settings-container .grid-1 {
    display: grid;
    grid-template-columns: auto auto;
    gap: 40px;
}

.settings-container .grid-2 {
    display: grid;
    grid-template-columns: auto auto;
    gap: 40px;
    margin-top: -15px;
}

.save-button {
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #3D7F39;
    width: 64px;
    padding: 16px;
    text-align: center;
    border-radius: 12px;
    font-size: 14px;
    background-color: #3D7F39;
    color: #FFFFFF;
}

.settings-container .field {
    margin-bottom: 20px;
}

.settings-container .field label {
    font-size: 14px;
}

.settings-container .field input {
    margin-top: 10px;
    width: 350px;
    height: 45px;
    font-size: 14px;
    border-radius: 8px;
    outline: 0px;
    border: 1px solid #333;
    padding-left: 10px;
}

.settings-container .field textarea {
    margin-top: 10px;
    width: 250px;
    height: 150px;
    font-size: 14px;
    border-radius: 8px;
    outline: 0px;
    border: 1px solid #333;
    padding: 10px;
}

.settings-container .field select {
    margin-top: 10px;
    width: 350px;
    height: 45px;
    font-size: 14px;
    border-radius: 8px;
    outline: 0px;
    border: 1px solid #333;
    padding: 10px;
}

.settings-container .field-caption {
    font-size: 12px;
    color: #646464;
    margin-top: 8px;
}

.card_farmer .card_container {
    text-align: center;
}

.card_farmer .card_container hr {
    border: 0px solid #E8E8E8;
    border-bottom: 1px solid #e8e8e8;
    width: 80%;
}

.card_farmer .card_container .title {
    font-weight: 600;
    font-size: 16px;
    color: #333333;
    margin-bottom: 5px;
    margin-top: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    max-width: 280px;
}

.card_farmer .card_container .pic {
    width: 75px;
    height: 75px;
    border-radius: 5px;
}

.card_farmer .card_container .location {
    font-size: 12px;
    color: #757575;
}

.card_farmer .card_container .budget {
    font-size: 14px;
    color: #333333;
    font-weight: 600;
    margin-bottom: 5px;
}

.card_farmer .card_container .amt-for {
    font-size: 14px;
    color: #333333;
    margin-bottom: 8px;
}

.card_farmer .card_container .approved {
    font-size: 12px;
    color: #757575;
    margin-bottom: 12px;
}

.card_farmer .card_container .connect {
    bottom: 0px;
    background-color: #3D7F39;
    color: #fff;
    margin: 0px;
    padding: 5px 0px;
    position: relative;
    bottom: 0px;
    border: 0px;
    outline: 0px;
    width: 100%;
    margin-top: -3px;
    border-radius: 8px;
}

.card_farmer .card_container .user-type {
    font-size: 12px;
    border: 1px solid #b3b3b3;
    color: #b3b3b3;
    padding: 1px 5px 2px 5px;
    float: left;
    border-radius: 100px;
}

.card_farmer .card_container .kyc {
    position: absolute;
    right: 0px;
}

.card_farmer .card_container .img {
    text-align: center;
}

.card_farmer .card_container .img img {
    width: 84px;
    height: 84px;
}

.card_farmer .card_container .top {
    position: relative;
    width: 100%;
}

/* Modal width */
.modal-60w {
    width: 60%;
    max-width: none !important;
}

.notify-msg img {
    width: 400px;
    margin-top: 10px;
}

ul.Land-images {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 15px;
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.Land-images>li {
    position: relative;
    padding-top: 66%;
}

ul.Land-images>li img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.nav-pills .nav-link.active>div {
    background-color: #E4F1E3 !important;
}

.react-multiple-carousel__arrow {
    z-index: 9 !important;
}

.deal {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    width: 800px;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 9px 23px #00000029;
    border: 1px solid #E8E8E8;
}

.deal .close {
    float: right;
    padding: 0px;
    margin: 0px;
    cursor: pointer;
}

.deal .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    background-color: rgb(242, 242, 242);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
}

 .deal .grid .sub{
    display: grid;
    grid-template-columns: 1fr 3fr;
    padding:10px;
    border-radius:10px;
    font-size: 14px;
}

.deal .grid .farmer{
    background-color: #E4F1E3;
}

.deal .grid .niveshak{
    background-color:#fff;
}

.deal .grid input {
    border: 1px solid #ccc;
    outline: none;
}

.deal .grid input:focus {
    border: 1px solid #888;
    outline: none;
}

.deal .grid img {
    width: 65px;
    height: 65px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.deal .deal-details{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
    gap: 15px;
    font-size: 14px;
    margin:15px 0px;
}

.deal .deal-details div{
    background-color:rgb(242, 242, 242);
    padding:10px;
    border-radius:8px;
}

.deal .submit-deal {
    width: 150px;
    border-radius:10px;
    margin:5px;
}