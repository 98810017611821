body {
    background: #FAFAFA;
}

.massage_main {
    display: grid;
    grid-template-columns: 450px 738px;
    gap: 40px;
    align-items: center;
    margin-top: 130px;
}

.massage_list_filter ul {
    font-size: 14px;
    margin: 0px;
    padding: 0px;
    list-style: none;
    border: 1px solid #E8E8E8;
    border-radius: 12px;
    background: #fff;
}

.massage_list_filter {
    overflow-y: scroll;
    height: 450px;
}

.massage_list_filter ul li {
    display: grid;
    grid-template-columns: 1fr 3fr auto;
    padding: 8px;
    border-bottom: 1px solid #E8E8E8;
    cursor: pointer;
    align-items: center;
}

.message__box__title {
    display: inline-block;
    width: 210px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.massage_list_filter ul li div {
    pointer-events: none;
}

.massage_list_filter li .actions {
    font-size: 10px;
}

.massage_list_filter li .item {
    padding: 5px;
}

.massage_list_filter li .item .unread {}

/* .massage_list_filter li .icon img {
    padding: 10px;
    border-radius: 12px;
    background-color: #E4F1E3;
} */
.massage_text_filter ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
    display: grid;
    grid-template-columns: auto auto;
}

.massage_text_filter h2 {
    margin: 0px;
    font-size: 30px;
    margin-bottom: 10px;
}

.massage_tab_filter ul {
    list-style: none;
    display: flex;
    gap: 8px;
    padding: 0px;
    margin-top: 10px;
}

.massage_tab_filter li {
    text-align: center;
    padding: 7px 17px;
    font-size: 14px;
    color: #757575;
    cursor: pointer;
}

.massage_list_filter ul li .unread-msg{
    background-color: #E4F1E3;
    padding: 3px 6px;
    border-radius: 100%;
    color: #666;
    font-size: 12px;
    vertical-align: top;
}

.massage_tab_filter .active-tab {
    background: #E4F1E3;
    border-radius: 12px;
    font-size: 14px;
    color: #2E5F2B;
}

.content h4 {
    font-size: 16px;
    letter-spacing: 0.43px;
    color: #333333;
    font-weight: 600;
}

.content .span {
    text-align: left;
    font-size: 10px;
    letter-spacing: 0.38px;
    color: #646464;
}

.content p {
    font-size: 13px;
    letter-spacing: 0.43px;
    color: #333;
    opacity: 1;
    margin: 0px;
    line-height: 22px;
}

.incoming__msg {
    width: 281px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px;
}

.icon.item img {
    width: 100%;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #e7e7e7;
}

.active-user {
    border: 1px solid #f5f5f5;
    background: #f5f5f5;
}

.chat-card-header ul {
    font-size: 14px;
    margin: 0px;
    padding: 0px;
    list-style: none;
    border: 1px solid #f5f5f5;
    border-radius: 12px 12px 0px 0px;
    background: #f5f5f5;
}

.chat-card-header li {
    display: grid;
    grid-template-columns: .4fr 3fr 0.7fr;
    padding: 8px;
}

.chat-card-header li .actions button{
    border-radius: 5px;
    width: 120px;
    background-color:#E4F1E3;
    border:1px solid #2E5F2B;
    color:#2E5F2B;
    font-weight: 700;
}

.chat-card-header li .item {
    padding: 5px;
}

.chat-card {
    border: 1px solid #E8E8E8;
    border-radius: 12px;
    background: #fff;
}

.chat-card-header .content span {
    line-height: 21px;
}



.welcome-header {
    display: grid;
    grid-template-columns: .4fr 3fr;
    font-size: 14px;
    gap: 20px;
    border-bottom: 1px solid #E8E8E8;
    padding-bottom: 10px;
}

.welcome-card {
    border: 1px solid #E8E8E8;
    border-radius: 12px;
    padding: 10px;
    width: 60%;
    margin-bottom: 25px;
}

.chat-card-body {
    width: 100%;
    height: 400px;
}

.welcome-content h6 {
    margin: 0px;
    font-size: 14px;
    color: #333333;
    line-height: 29px;
    margin-top: 5px;
}

.welcome-content p {
    margin: 0px;

}

/* .inner-notifi-massage {
    background: #E4F1E3;
    border-radius: 12px;
    width: 90px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    margin-top: 10px;
    margin-bottom: 10px;
} */

.inner-notifi-massage {
    background: #E4F1E3;
    border-radius: 12px;
}

.inner-notifi-massage p {
    color: #3D7F39;
    letter-spacing: 0.38px;
    font-size: 16px;
}

.massage_1 {
    display: grid;
    grid-template-columns: 45px auto;
    gap: 20px;
    margin-bottom: 10px;
}

.massage_1 .content p {
    /* max-width: 80%; */
    float: left;
    color: #333;
    padding: 0px 5px;
    max-width: 450px;
    overflow: hidden;
}

.check-icon img {
    width: 12px;
    margin: 0px 10px;
}

.chat-inner {
    margin-bottom: 10px;
    background: #E4F1E3;
    padding: 7px 10px;
    border-radius: 12px;
    width: max-content;
}

.massage-type {
    width: 86%;
    border: none;
    padding-left: 10px;
    outline: none;
}

.send-button {
    background: #3D7F39;
    color: #fff;
    border: none;
    padding: 10px 21px;
    border-radius: 50px;
    font-size: 16px;
}

.chat-card-footer {
    background: #E4F1E3;
    padding: 10px;
    position: relative;
}

.inner-footer {
    background: #fff;
    border: 1px solid #E8E8E8;
    border-radius: 12px;
    padding: 10px;
    display: flex;
}

.inner-footer button {
    width: 130px;
    border-radius: 100px;
}

.delete-user-chat {
    opacity: 0.3;
    cursor: no-drop !important;
}

.delete-user-chat .content p {
    color: #E51D1D;
    opacity: 1;
    z-index: 999 !important;
}

.reply-massage {
    /* width: 85%;
    position: relative;
    margin-bottom: 10px; */
}

.reply-massage .massage_1 {
    grid-template-columns: auto 40px;
}

/* ::-webkit-scrollbar{
    width: 3px;
    border-radius: 12px;
    height: 50px;
    background: #E8E8E8;
} */

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-thumb {
    background: rgb(182, 182, 182);
    opacity: 0.7;
    height: 50px;
}

::-webkit-scrollbar-track {
    background: #fff;
    opacity: 0.7;
}

.first-chat .chat-inner:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 14px solid transparent;
    border-right: 24px solid #E4F1E3;
    border-bottom: 10px solid transparent;
    left: -12px;
    position: absolute;
}

.content.item {
    position: relative;
}

.reply-massage .chat-inner::after {
    content: '';
    width: 0;
    height: 0;
    border-top: 14px solid transparent;
    border-right: 24px solid #E4F1E3;
    border-bottom: 10px solid transparent;
    right: -14px;
    position: absolute;
    transform: rotate(180deg);
}

.reply-massage .msg {
    display: flex;
    align-items: end;
    flex-direction: column;
}

.actions {
    font-size: 12px;
    cursor: pointer;
}


.popup {
    position: fixed;
    background-color: #000000CC;
    z-index: 10;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

